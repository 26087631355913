import React from 'react';
import {Helmet} from "react-helmet";
import EIB from '../assets/svg/logo/logo-black.svg'
import {Link} from "gatsby";
const NotFound = () => {

    return (
        <>
            <div className='container-custom page d-flex page404'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Page introuvable | Collège Lycée Lafayette Nice</title>
                    <meta name="description" content="Le Collège Lycée Lafayette offre cursus bilingue et pluridisciplinaire : éducation bi-culturelle, une pédagogie différenciée, la confiance et l'estime de soi." />
                </Helmet>
                <div className='content w-100 fadeInEarth'>
                    <div className='text text-center'>
                        <div className='eib-404'>
                            <img src={EIB} alt='Collèle Lycée Lafayette' />
                        </div>
                        <div className='content-title text-center w-100 mb-0'>
                            <h1>Erreur 404</h1>
                        </div>
                        <p className='text-center mb-3'>Oops ! La page demandée est introuvable.</p>
                        <Link to='/'>
                            <button className='btn btn-primary'>
                                Revenir sur la page d'accueil
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotFound;